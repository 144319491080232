@use '@angular/material' as mat;
@include mat.core();

// Define a custom mixin that takes in the current theme
@mixin theme-color-grabber($theme) {
    // Parse the theme and create variables for each color in the pallete
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    // Create theme specfic styles
    .primaryColor {
        color: mat.m2-get-color-from-palette($primary);
    }

    .accentColor {
        color: mat.m2-get-color-from-palette($accent);
    }

    .warnColor {
        color: mat.m2-get-color-from-palette($warn);
    }

    .primaryColorBackground {
        background-color: mat.m2-get-color-from-palette($primary);
    }

    .accentColorBackground {
        background-color: mat.m2-get-color-from-palette($accent);
    }

    .warnColorBackground {
        background-color: mat.m2-get-color-from-palette($warn);
    }
}

//		Light Themes
$deeppurple-amber-primary: mat.m2-define-palette(mat.$m2-deep-purple-palette, 500);
$deeppurple-amber-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$deeppurple-amber-warn: mat.m2-define-palette(mat.$m2-red-palette);

$mat-theme-light-deeppurple-amber: mat.m2-define-light-theme($deeppurple-amber-primary, $deeppurple-amber-accent, $deeppurple-amber-warn);

.deeppurple-amber {
    @include mat.all-component-themes($mat-theme-light-deeppurple-amber);
    @include theme-color-grabber($mat-theme-light-deeppurple-amber);
}


$teal-orange-primary: mat.m2-define-palette(mat.$m2-teal-palette, 500);
$teal-orange-accent: mat.m2-define-palette(mat.$m2-deep-orange-palette, A200, A100, A400);
$teal-orange-warn: mat.m2-define-palette(mat.$m2-red-palette);

$mat-theme-light-teal-orange: mat.m2-define-light-theme((color: (primary: $teal-orange-primary,
                accent: $teal-orange-accent,
                warn: $teal-orange-warn ),
            typography: mat.m2-define-typography-config(),
            density: 0));


.teal-orange {
    @include mat.all-component-themes($mat-theme-light-teal-orange);
    @include theme-color-grabber($mat-theme-light-teal-orange);
}


//		Dark Themes
$pink-bluegrey-primary: mat.m2-define-palette(mat.$m2-pink-palette, 500);
$pink-bluegrey-accent: mat.m2-define-palette(mat.$m2-blue-grey-palette, A200, A100, A400);
$pink-bluegrey-warn: mat.m2-define-palette(mat.$m2-red-palette);

$mat-theme-dark-pink-bluegrey: mat.m2-define-dark-theme($pink-bluegrey-primary, $pink-bluegrey-accent, $pink-bluegrey-warn);

.pink-bluegrey {
    @include mat.all-component-themes($mat-theme-dark-pink-bluegrey);
    @include theme-color-grabber($mat-theme-dark-pink-bluegrey);
}


$purple-green-primary: mat.m2-define-palette(mat.$m2-purple-palette, 500);
$purple-green-accent: mat.m2-define-palette(mat.$m2-green-palette, A200, A100, A400);
$purple-green-warn: mat.m2-define-palette(mat.$m2-red-palette);

$mat-theme-dark-purple-green: mat.m2-define-dark-theme($purple-green-primary, $purple-green-accent, $purple-green-warn);

.purple-green {
    @include mat.all-component-themes($mat-theme-dark-purple-green);
    @include theme-color-grabber($mat-theme-dark-purple-green);
}


html,
body {
    margin: 0px;
    height: 100%;
    width: 100%;
}

.flex-container {
    display: flex;
}

.flex-container-row {
    flex-direction: row;
}

.flex-container-column {
    flex-direction: column;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-space-between {
	justify-content: space-between;
}

.flex-justify-left {
    justify-content: left;
}

.flex-align-items-center {
    align-items: center;
}

.flex-align-items-stretch {
    align-items: stretch;
}

.flex-align-content-center {
    align-content: center;
}

.flex-row-gap {
    row-gap: 15px;
}

.flex-column-gap {
    column-gap: 15px;
}

.flex-gap {
    gap: 15px 15px;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-wrap {
    flex-wrap: wrap;
}

.margin-top-15 {
    margin: 15px
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.mt-3 {
    margin-top: 15px;
}

.mt-2 {
    margin-top: 10px;
}

.mt-1 {
    margin-top: 5px;
}

.mb-3 {
    margin-bottom: 15px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-1 {
    margin-bottom: 5px;
}


.mr-3 {
    margin-right: 15px;
}

.mr-2 {
    margin-right: 10px;
}

.mr-1 {
    margin-right: 5px;
}

.ml-3 {
    margin-left: 15px;
}

.ml-2 {
    margin-left: 10px;
}

.ml-1 {
    margin-left: 5px;
}

.m-3 {
    margin: 15px;
}

.p-3 {
    padding: 15px;
}

.p-1 {
	padding: 5px;
}

.pt-3 {
    padding-top: 15px;
}

.pt-2 {
    padding-top: 10px;
}

.pt-1 {
    padding-top: 5px;
}

.pr-3 {
    padding-right: 15px;
}

.pr-2 {
    padding-right: 10px;
}

.pr-1 {
    padding-right: 5px;
}

.pl-3 {
    padding-left: 15px;
}

.pl-2 {
    padding-left: 10px;
}

.pl-1 {
    padding-left: 5px;
}

button:focus {
    outline: none !important;
}

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
    width: auto !important;
}

@media(min-width:300px) and (max-width:600px) {
    .mat-tab-labels {
        flex-direction: column !important;
        display: flex !important;
    }
}

.min-material-card-width {
    min-width: 350px;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
}